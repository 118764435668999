.addPaper {
  width:fit-content;
  height:fit-content;
  position: relative;
  float:right;
  transform: rotate(5deg);

  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.addPaper form{
  font-size: 5rem;
  position: absolute;
  z-index: 1;
  
}
.addPaper form textarea {
  height:35vh;
  width:40vw;
  font-size: 6.4vh;
  background-color: 0;
  outline: none;
  background: transparent;
  border:none;
  resize:none;
  overflow: hidden;
  position: relative;
  font-family: 'Just Another Hand', cursive;
  left:8.5vw;
  top:7vw;
}
.directions {
  width:30vw;
  height:auto;
  position: absolute;
  top:-8vw;
  right:45vw;
  z-index: -1;
}
.pencilcase {
  width:30vw;
  height:auto;
  position:absolute;
  bottom:0;
  left:0;
  margin-left: 0vw;
  margin-bottom: -47vh;
  transform: rotate(30deg);
  z-index: 1;
}
.eraser {
  width: 10vw;
  height:auto;
  position:absolute;
  bottom: -12vh;
  right: 38vw;
}
.paperclip {
  width:2vw;
  height: auto;
  position:absolute;
  top:25px;
  right:20vw;
}
.paperclip2 {
  width:2vw;
  height: auto;
  position: absolute;
  top: 10px;
  right: 22.5vw;
}

#paperImage {
  margin-top: 25vh;
  margin-right: 0vw;
  width:50vw;
  height:auto;
  position: 
  relative;
}
.pen{
  position:absolute;
  width:10vw;
  right:7vw;
  bottom:0;
}
.pencil {
  margin: -0.2vw;
  width:3vw;
}
.pencilsWrapper{
  position:absolute;
  bottom: 0;
  left:12vw;
  z-index: 0;
}