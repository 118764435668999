#desk {
    width:100vw;
    height:100vh;
    position: fixed;
    z-index: -1;
}
#addPaper {
    height:22vh;
    width:20vw;
    position: absolute;
    bottom: 10vh;
    right:-5vw;
    z-index: 1;
}
#journalPaper {
    height:auto;
    width:20vw;
    position: absolute;
    bottom: 40vh;
    right:5vw;
}
#papers{
    height:auto;
    width:30vw;
    position: absolute;
    top: 8vh;
    left:15vw;
    z-index: -1;
}
#addPile{
    width:20vw;
    height:auto;
    position: absolute;
    left:5vw;
    bottom:5vw;
}