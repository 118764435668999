input {
    width: calc(100% - 1.1em);
    border: 0.1px solid #d3d3d3;
    border-radius: 0.3em;
    font-size: 0.7em;
    padding: 0.5em;
    transition: 0.1s;
}

input:focus {
    outline: none !important;
    border-color: #3A1F04;
    transition: 0.1s;
}

input::placeholder {
    color: #9d9d9d;
}

form > div {
    width: 100%;
    font-family: sans-serif;
    font-size: 1.5em;
}

form {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

button {
    height: 2em;
    width: 100%;
    border: 0.1px solid #d3d3d3;
    border-radius: 0.3em;
    font-family: sans-serif;
    font-size: 1em;
}